<template>
  <div class="d-flex flex-column justify-content-center h-100">
    <div class="pb-3">
      <img src="@/assets/images/404.png">
    </div>
    <h2>{{ $t("pageNotFound.title") }}</h2>
    <p>{{ $t("pageNotFound.message") }}</p>
    <p>
      <router-link :to="{name: 'Home'}">
        {{ $t("pageNotFound.link") }}
      </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
